* {
  box-sizing: border-box;
}

.menu-btn {
  position: absolute;
  z-index: 3;
  right: 35px;
  top: 35px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}
.menu-btn .btn-line {
  width: 28px;
  height: 3px;
  margin: 0 0 5px 0;
  background: #fff;
  transition: all 0.5s ease-out;
}
.menu-btn.close {
  transform: rotate(180deg);
}
.menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
.menu-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}
.menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.menu {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 0.9;
  visibility: hidden;
}
.menu.show {
  visibility: visible;
}
.menu-branding,
.menu-nav {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  float: left;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.menu-nav {
  margin: 0;
  padding: 0;
  background: #373737;
  list-style: none;
  transform: translate3d(0, -100%, 0);
  transition: all 0.5s ease-out;
}
.menu-nav.show {
  transform: translate3d(0, 0, 0);
}
.menu-branding {
  background: #444;
  transform: translate3d(0, 100%, 0);
  transition: all 0.5s ease-out;
}
.menu-branding.show {
  transform: translate3d(0, 0, 0);
}
.menu-branding .portrait {
  width: 250px;
  height: 250px;
  background: url("/img/images.jpeg");
  border-radius: 50%;
  border: solid 3px #eece1a;
}
.menu .nav-item {
  transform: translate3d(600px, 0, 0);
  transition: all 0.5s ease-out;
}
.menu .nav-item.show {
  transform: translate3d(0, 0, 0);
}
.menu .nav-link {
  display: inline-block;
  position: relative;
  font-size: 30px;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s ease-out;
}
.menu .nav-item .current {
  color: #eece1a;
}
.menu .nav-link:hover {
  color: #e0e028;
  transform: scale(1.25);
}

.nav-item:nth-child(1) {
  transition-delay: 0.1s;
}

.nav-item:nth-child(2) {
  transition-delay: 0.2s;
}

.nav-item:nth-child(3) {
  transition-delay: 0.3s;
}

.nav-item:nth-child(4) {
  transition-delay: 0.4s;
}

body {
  background: #444;
  color: #fff;
  height: 100%;
  margin: 0;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
}
h1,
h2,
h3 {
  margin: 0;
  font-weight: 400;
}
h1.lg-heading,
h2.lg-heading,
h3.lg-heading {
  font-size: 6rem;
}
h1.sm-heading,
h2.sm-heading,
h3.sm-heading {
  margin-bottom: 2rem;
  padding: 0.2rem 1rem;
  background: rgba(73, 73, 73, 0.5);
}

a {
  color: #fff;
  text-decoration: none;
}

header {
  position: fixed;
  z-index: 2;
  width: 100%;
}

.text-secondary {
  color: #eece1a;
}

.landing {
  position: relative;
  /* background: url('/img/background.jpg') no-repeat center center/cover; */
  background: url("/img/voice-over5.webp") no-repeat center center/cover;
  height: 100vh;
}
.dark-overlay {
  background-color: rgba(68, 68, 68, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

main {
  padding: 4rem;
  min-height: calc(100vh - 60px);
}
main .icons {
  margin-top: 1rem;
}
main .icons a {
  padding: 0.4rem;
}
main .icons a:hover {
  color: #eece1a;
  transition: all 0.5s ease-out;
}
main#home {
  overflow: hidden;
}
main#home h1 {
  margin-top: 20vh;
}

.about-info {
  display: grid;
  grid-gap: 30px;
  grid-template-areas: "bioimage bio bio" "job1 job2 job3";
  grid-template-columns: repeat(3, 1fr);
}
.about-info .bio-image {
  grid-area: bioimage;
  margin: auto;
  border-radius: 50%;
  border: #eece1a 3px solid;
}
.about-info .bio {
  grid-area: bio;
  font-size: 1.5rem;
}
.about-info .job-1 {
  grid-area: job1;
}
.about-info .job-2 {
  grid-area: job2;
}
.about-info .job-3 {
  grid-area: job3;
}
.about-info .job {
  background: #515151;
  padding: 0.5rem;
  border-bottom: #eece1a 5px solid;
}

.certificate {
  margin-top: 20px;
  background: #515151;
  padding: 0.5rem;
  border-bottom: #eece1a 5px solid;
}

.certs {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.projects {
  display: grid;
  grid-gap: 0.7rem;
  grid-template-columns: repeat(3, 1fr);
}
.projects img {
  width: 100%;
  border: 3px #fff solid;
}
.projects img:hover {
  opacity: 0.5;
  border-color: #eece1a;
  transition: all 0.5s ease-out;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
}
.boxes div {
  font-size: 2rem;
  border: 3px #fff solid;
  padding: 1.5rem 2.5rem;
  margin-bottom: 3rem;
  transition: all 0.5s ease-out;
}
.boxes div:hover {
  padding: 0.5rem 1.5rem;
  background: #eece1a;
  color: #000;
}
.boxes div:hover span {
  color: #000;
}

.btn,
.btn-dark,
.btn-light {
  display: block;
  padding: 0.5rem 1rem;
  border: 0;
  margin-bottom: 0.3rem;
}
.btn:hover,
.btn-dark:hover,
.btn-light:hover {
  background: #eece1a;
  color: #000;
}

.btn-dark {
  background: black;
  color: #fff;
}

.btn-light {
  background: #c4c4c4;
  color: #333;
}

#main-footer {
  text-align: center;
  padding: 1rem;
  background: #2b2b2b;
  color: #fff;
  height: 60px;
}

.skills {
  display: flex;
  align-items: center;
  /*justify-content: center;*/
  text-align: center;
}

.p-1 {
  padding: 1rem;
}

.profile {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 4fr 2fr;
  grid-template-columns: 2fr 4fr 2fr;
  grid-gap: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  border-bottom: #eece1a 5px solid;
  background: #515151;
  color: #fff;
}
.profile .bg-light {
  border-bottom: #eece1a 5px solid;
  background: #515151;
  color: #fff;
}
.profile ul {
  list-style: none;
  border: 0px solid #fff;
  text-align: center;
}
.profile img {
  width: 100%;
}

.contact-details {
  text-align: center;
  margin-top: 20px;
  font-size: 1.5rem;
  line-height: 4rem;
}

#contact {
  height: 100%;
}

@media screen and (min-width: 1171px) {
  .projects {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 769px) and (max-width: 1170px) {
  .projects {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  main {
    align-items: center;
    text-align: center;
  }
  main .lg-heading {
    line-height: 1;
    margin-bottom: 1rem;
  }
  ul.menu-nav,
  div.menu-branding {
    float: none;
    width: 100%;
    min-height: 0;
  }
  ul.menu-nav.show,
  div.menu-branding.show {
    transform: translate3d(0, 0, 0);
  }
  .menu-nav {
    height: 75vh;
    transform: translate3d(-100%, 0, 0);
    font-size: 24px;
  }
  .menu-branding {
    height: 25vh;
    transform: translate3d(100%, 0, 0);
  }
  .menu-branding .portrait {
    background: url("/img/images.jpeg");
    width: 150px;
    height: 150px;
  }
  .about-info {
    grid-template-areas: "bioimage" "bio" "job1" "job2" "job3";
    grid-template-columns: 1fr;
  }
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
  .profile {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .profile ul {
    display: flex;
    justify-content: space-evenly;
  }
  .certs {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  main {
    padding: 2rem;
  }
  main #home h1 {
    margin-top: 10vh;
  }
  main .lg-heading {
    margin-top: 1rem;
    font-size: 4rem;
  }
  .skills {
    display: none;
  }
  .projects {
    grid-template-columns: 1fr;
  }
  .profile {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .profile ul {
    display: none;
  }

  .contact-details {
    font-size: 1.1rem;
  }
}
